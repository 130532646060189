define("discourse/plugins/discpage/discourse/lib/lib", ["exports", "discourse/lib/plugin-api", "discourse/lib/formatter", "discourse-common/lib/icon-library", "discourse/models/user", "discourse/components/topic-navigation", "discourse/routes/application", "ember", "@ember/runloop"], function (_exports, _pluginApi, _formatter, _iconLibrary, _user, _topicNavigation, _application, _ember, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.init = init;
  var x = function () {
      for (var _len = arguments.length, a = new Array(_len), _key = 0; _key < _len; _key++) {
        a[_key] = arguments[_key];
      }
      a = ["%cDiscPage -", "color:grey", ...a];
      console.log(...a);
    },
    z = function () {
      for (var _len2 = arguments.length, a = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        a[_key2] = arguments[_key2];
      }
      a = ["%cDiscPage Error -", "color:red", ...a];
      console.log(...a);
    },
    A = function () {
      for (var _len3 = arguments.length, a = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        a[_key3] = arguments[_key3];
      }
      a = ["%cDiscPage Warning -", "color:orange", ...a];
      console.log(...a);
    },
    B = class extends Error {
      constructor(a) {
        super(a);
        this.constructor = B;
        this.__proto__ = B.prototype;
        this.message = a;
        this.name = "DiscpageError";
      }
    },
    C = (a, b) => {
      if (!a) throw new B(`Assertion Failed${b ? " - " + b : ""}`);
    };
  function D(a, b, d, f) {
    let e = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
    let l = (c, g) => {
        try {
          const k = b(a[e], e, a);
          k && k.then ? k.then(c).catch(g) : c(k);
        } catch (k) {
          g(k);
        }
      },
      h = (c, g) => () => D(a, b, c, g, ++e),
      q = (c, g) => e < a.length ? new Promise(l).then(h(c, g)).catch(g) : c();
    return d ? q(d, f) : new Promise(q);
  }
  var E = a => new Promise(b => {
    setTimeout(() => {
      b(void 0);
    }, a);
  });
  function F(a, b, d) {
    let f = e => E(d).then(() => a(e));
    try {
      return 0 === b ? Promise.reject(void 0) : Promise.resolve(a(b)).then(e => e || F(f, b - 1));
    } catch (e) {
      return Promise.reject(e);
    }
  }
  var G = /^[0-9]+$/,
    I = /^[0-9A-Za-z_]+$/;
  function aa(_ref) {
    let {
      a,
      s: b
    } = _ref;
    if (!G.test(a)) throw new B(`Invalid pageId "${a}"`);
    if (b && !I.test(b)) throw new B(`Invalid balloon id "${b}". Valid characters are: [0-9A-Za-z_].`);
    return b ? `${"dpg"}-${a}-${b}` : `${"dpg"}-${a}`;
  }
  function J(a) {
    var b = a.split("-");
    if ("dpg" !== b.shift()) return null;
    a = b.shift();
    return G.test(a) ? (b = b.shift()) && !I.test(b) ? null : {
      a,
      s: b
    } : null;
  }
  function K(_ref2) {
    let {
      method: a,
      path: b,
      I: d
    } = _ref2;
    return new Promise((f, e) => {
      $.ajax({
        type: a,
        url: b,
        data: d,
        success: l => f(l)
      }).fail(l => e(l.responseText));
    });
  }
  function ba(_ref3) {
    let {
      tag: a
    } = _ref3;
    return K({
      method: "GET",
      path: `/tag/${a}.json`
    }).then(b => b.topic_list.topics);
  }
  function ca(_ref4) {
    let {
      name: a,
      D: b,
      P: d = !1,
      R: f = !1
    } = _ref4;
    K({
      method: "POST",
      path: "/tag_groups",
      I: {
        name: a,
        tag_names: b,
        one_per_topic: d,
        permissions: f ? {
          staff: 1
        } : void 0
      }
    });
  }
  function da(_ref5) {
    let {
      id: a,
      D: b
    } = _ref5;
    K({
      method: "PUT",
      path: `/tag_groups/${a}.json`,
      I: {
        tag_names: b
      }
    });
  }
  function M(a, _ref6) {
    let {
      f: b
    } = _ref6;
    var d = $(a.left);
    d.find(".dpg-balloon-text, .dpg-subsec").removeClass("dpg-highlighted");
    b && (d = d.find(`.dpg-balloon-text[data-dpg-id=${b}]`), d.length ? (d.addClass("dpg-highlighted"), d.parent().is("h1,h2,h3,h4,h5,h6") && d.closest(".dpg-subsec").addClass("dpg-highlighted"), b = d[0].getBoundingClientRect(), a = a.left.getBoundingClientRect(), b.top < a.bottom && b.bottom >= a.top || d[0].scrollIntoView()) : A(`selected balloon "${b}" has not been found in page "${a.a}"`));
  }
  function N(a, _ref7) {
    let {
      a: b,
      i: d,
      h: f,
      g: e,
      m: l,
      c: h,
      title: q,
      f: c
    } = _ref7;
    C("string" == typeof b, `invalid pageId "${b}"`);
    h = h.replace("{dpg-show-rev-button}", "").replace("{dpg-title-balloon}", "");
    let g = $(`
      <div class="dpg-page-content">
        <div class="dpg-buttons ${"nodiff" !== e ? "selected" : ""}">
          <div class="dpg-buttons-left"></div><div class="dpg-buttons-center"></div><div class="dpg-buttons-right"></div>        
        </div>
        <div class="dpg-header">
          <div class="dpg-header-1"><div class="dpg-header-2"><div class="dpg-header-3"></div></div></div>
        </div>
        <div class="dpg-body">
          <div class="wrap">
            <!-- <div class="posts-wrapper"> FIX FOR ISSUE https://github.com/sylque/discpage/issues/6 --> 
              <div class="topic-body">
                <!-- Cooked post to be inserted here -->
              </div>
            <!-- </div> -->
          </div>
        </div>
        <div class="dpg-footer">
          <div class="dpg-footer-1"><div class="dpg-footer-2"><div class="dpg-footer-3"></div></div></div>
        </div>
      </div>
    `);
    var k = a.c.includes("{dpg-title-balloon}") ? '<span class="dpg-balloon-text" data-dpg-id="title"></span>' : "";
    k = a.G.cooked(`<h1>${q + k}</h1>\n` + h).init();
    g.find(".dpg-body .topic-body").append(k);
    let r = a.j.siteSettings.force_lowercase_tags,
      u = a.j.siteSettings.max_tag_length;
    g.find(".dpg-badge").hide();
    let w = {};
    g.find(".dpg-balloon-text").each((m, p) => {
      let n,
        t = p.dataset.dpgId;
      m = $(p);
      try {
        if (!t) throw new B("Missing balloon id. The correct syntax is [dpgb id=something][/dpgb].");
        n = aa({
          a: b,
          s: t
        });
        if (n.length > u) throw new B(`Balloon id is too long. Resulting tag is \"${n}\", which has a length of ${n.length}. This doesn't fit max_tag_length=${u} in Discourse settings. Fix: either shorten the balloon id, or increase max_tag_length.`);
        if (r && n !== n.toLowerCase()) throw new B("Balloon id has uppercase. This doesn't fit force_lowercase_tags=true in Discourse settings. Fix: either make your balloon id all lowercase, or set force_lowercase_tags to false.");
        w[n] && A(`Duplicate balloon id "${n}". This is usually a bad idea.`);
      } catch (v) {
        if (v instanceof B) {
          z(v.message);
          m.append(`<span class="dpg-error" title="${v.message}">DiscPage Error</span>`);
          return;
        }
        throw v;
      }
      w[n] = !0;
      if (0 === p.childNodes.length) {
        p = m.parent().is(".cooked,.dpg-subsec");
        let v = m.prev();
        p = p && v.length ? v : m.parent();
        m.detach();
        p.addClass("dpg-balloon-parent").wrapInner(m);
      } else m.wrap('<span class="dpg-balloon-parent" />'), p = m.parent();
      p.append(`
        <span class="dpg-icons" title="Click to discuss this part">
          <span class="dpg-balloon">${(0, _iconLibrary.iconHTML)("comment")}</span>
          <span class="dpg-badge" style="display:none">99</span>
        </span>
      `);
      p.is("h1,h2,h3,h4,h5,h6") && p.nextUntil("h1,h2,h3,h4,h5,h6").addBack().wrapAll('<div class="dpg-subsec"></div>');
      p.find(".dpg-icons").click(v => {
        a.j.get("router").transitionTo(`/tag/${n}`);
        M(a, {
          f: t
        });
        v.stopPropagation();
      });
    });
    let y = Object.keys(w);
    a.F && y.length && a.M.then(m => {
      let p = `dpg-${b}`;
      m = m.find(n => n.name === p);
      y.sort();
      m ? ea(m.J, y) || da({
        id: m.id,
        D: y
      }) : ca({
        name: p,
        D: y
      });
    });
    a.N.then(m => {
      m = m.reduce((p, n) => {
        if (n.count && n.parsed.a === b) {
          const t = g.find(`.dpg-balloon-text[data-dpg-id="${n.parsed.s}"]`);
          t.length ? (n.K = t.next().find(".dpg-badge"), p.push(n)) : A(`In page "${b}": missing balloon for tag "${n.id}"`);
        }
        return p;
      }, []);
      D(m, p => ba({
        tag: p.id
      }).then(n => {
        (n = n.filter(t => t.visible).length) && p.K.text(n).show();
      }).then(() => E(250)));
    });
    k = g.find(".dpg-buttons-right");
    let L = g.find(".dpg-buttons-center");
    var H = a.c.includes("{dpg-show-rev-button}");
    if (!a.C && 1 < f && H) {
      let m = _ref8 => {
          let {
            g: n,
            rev: t = null
          } = _ref8;
          N(a, {
            a: b,
            i: d,
            h: f,
            g: n,
            m: t ? t.created_at : void 0,
            c: t ? t.body_changes.inline : a.c,
            title: q,
            f: c
          });
        },
        p = "nodiff" !== e;
      O({
        o: "history",
        title: "Show page revisions",
        id: "dpg-show-rev-nav"
      }).click(() => {
        p ? m({
          g: "nodiff"
        }) : P(`/posts/${d}/revisions/${f}.json`).then(n => {
          m({
            g: f,
            rev: n
          });
        });
      }).appendTo(k);
      if (p) {
        O({
          o: "backward",
          title: "Previous revisions",
          id: "dpg-prev-rev",
          disabled: 2 === e
        }).appendTo(L).click(() => {
          let t = e - 1;
          P(`/posts/${d}/revisions/${t}.json`).then(v => {
            m({
              g: t,
              rev: v
            });
          });
        });
        H = new Date(l);
        let n = (0, _formatter.relativeAge)(H, {
          format: "medium-with-ago"
        });
        L.append(`<span class="dpg-date" title=${H}>${n}</span>`);
        O({
          o: "forward",
          title: "Next revision",
          id: "dpg-next-rev",
          disabled: e === f
        }).appendTo(L).click(() => {
          let t = e + 1;
          P(`/posts/${d}/revisions/${t}.json`).then(v => {
            m({
              g: t,
              rev: v
            });
          });
        });
      }
    }
    a.F && (O({
      o: "wrench",
      title: "Edit title",
      id: "dpg-edit-title-button"
    }).click(() => {
      $("html").toggleClass("dpg", !1);
      $("a.edit-topic").click();
      $("#main-outlet").click(m => {
        m.target.closest(".edit-controls .btn, .topic-admin-popup-menu .topic-admin-reset-bump-date, .topic-admin-popup-menu .topic-admin-visible") && (N(a, {
          a: b,
          i: d,
          h: f,
          g: e,
          m: l,
          c: h,
          title: $("input#edit-title").val(),
          f: c
        }), $("html").toggleClass("dpg", !0));
      });
    }).wrap("<div><div>").parent().appendTo(k), O({
      o: "pencil-alt",
      title: "Edit page",
      id: "dpg-edit-page-button"
    }).click(() => {
      let m = $("article#post_1 button.edit");
      m.length ? (m.click(), Q(a.C)) : ($("article#post_1 button.show-more-actions").click(), setTimeout(() => {
        $("article#post_1 button.edit").click();
        Q(a.C);
      }, 0));
    }).wrap("<div><div>").parent().appendTo(k));
    $(a.left).empty().append(g);
    M(a, {
      f: c
    });
    document.documentElement.dispatchEvent(new CustomEvent("dpg_displaypage", {
      detail: {
        pageId: parseInt(b),
        title: q,
        cooked: h,
        node: g[0],
        selTriggerId: c,
        curRevNum: e,
        curRevDate: l
      }
    }));
  }
  function R(a) {
    N(a, {
      a: "error",
      i: void 0,
      h: void 0,
      g: "nodiff",
      m: void 0,
      c: "<p>Please contact your administrator.</p>",
      title: "Oops! That page doesn't exist anymore",
      f: null
    });
  }
  function S(a, _ref9) {
    let {
      a: b,
      i: d,
      h: f,
      c: e,
      title: l,
      f: h
    } = _ref9;
    C("string" === typeof b);
    b === a.a && 1 !== a.l || h || a.left.scrollTo(0, 0);
    d && f && e && l ? b === a.a && e === a.c ? M(a, {
      f: h
    }) : (a.a = b, a.c = e, N(a, {
      a: b,
      i: d,
      h: f,
      g: "nodiff",
      m: void 0,
      c: e,
      title: l,
      f: h
    })) : b === a.a ? M(a, {
      f: h
    }) : P(`/t/${b}.json`).then(q => {
      a.a = b;
      if (a.L.find(c => c.id === q.category_id)) {
        let c = q.post_stream.posts[0];
        a.c = c.cooked;
        N(a, {
          a: b,
          i: c.id,
          h: c.version,
          g: "nodiff",
          m: void 0,
          c: a.c,
          title: q.fancy_title,
          f: h
        });
      } else a.c = "error", x(`Won't display static page ${b}, because category ${q.category_id} is not a static page`), R(a);
    }).catch(() => {
      a.c = "error";
      x(`Won't display static page ${b}, because it doesn't exist or is private`);
      R(a);
    });
  }
  function T(a, b, d, f) {
    a.H.animate ? (a = a.H.animate([{
      left: b
    }, {
      left: d
    }], {
      duration: 200
    }), f && (a.onfinish = f)) : f && f();
  }
  function fa(a, b) {
    T(a, "100%", 1035 <= window.innerWidth ? "50%" : "0%", b);
  }
  function U(a, b) {
    if (b !== a.l) {
      switch (a.l) {
        case null:
        case 1:
          $("html").attr("data-dpg-layout", b);
          break;
        case 0:
        case 2:
          3 === b ? fa(a, () => {
            $("html").attr("data-dpg-layout", b);
          }) : $("html").attr("data-dpg-layout", b);
          break;
        case 3:
          $("html").attr("data-dpg-layout", b);
          0 !== b && 2 !== b || T(a, 1035 <= window.innerWidth ? "50%" : "0%", "100%");
          break;
        default:
          throw new B(void 0);
      }
      if (2 == b) {
        let d = $(a.left).find(".dpg-balloon-text.dpg-highlighted");
        d.length && d[0].scrollIntoView();
      }
      a.l = b;
    }
  }
  class ha {
    constructor(a, b) {
      this.j = a;
      this.L = b;
      this.C = a.site.mobileView;
      this.left = document.getElementById("dpg-left");
      this.H = document.getElementById("dpg-ghost");
      this.c = this.a = this.l = null;
      this.F = (a = _user.default.current()) && a.admin;
      this.N = K({
        method: "GET",
        path: "/tags.json"
      }).then(d => d.tags.reduce((f, e) => {
        e.parsed = J(e.id);
        return e.parsed ? [...f, e] : f;
      }, []));
      this.F && (this.M = K({
        method: "GET",
        path: "/tag_groups.json"
      }).then(d => d.tag_groups.reduce((f, e) => {
        e = {
          id: e.id,
          name: e.name,
          J: e.tag_names
        };
        if (e.name && e.name.startsWith("dpg-")) {
          if (I.test(e.name.substring(4))) return e.J.sort(), [...f, e];
          A(`Invalid discpage tag group "${e.name}"`);
        }
        return f;
      }, [])));
    }
  }
  function V() {
    $("html").toggleClass("dpg-wide", 1035 <= window.innerWidth);
  }
  window.addEventListener("resize", V);
  V();
  let P = a => new Promise((b, d) => {
    $.get(a, f => b(f)).fail(() => d(`get "${a}" failed`));
  });
  function ea(a, b) {
    return a.length === b.length && a.every((d, f) => d === b[f]);
  }
  function O(_ref10) {
    let {
      o: a,
      title: b,
      id: d = "",
      O: f = "",
      disabled: e = !1
    } = _ref10;
    return $(`    
    <button ${b ? `title="${b}"` : ""} ${d ? `id="${d}"` : ""} ${e ? 'disabled=""' : ""} class="btn-default btn no-text btn-icon ${f || ""}" type="button">    
      <svg class="fa d-icon d-icon-${a} svg-icon svg-string" xmlns="http://www.w3.org/2000/svg">
        <use xlink:href="#${a}"></use>
      </svg>
    </button>
  `);
  }
  function Q(a) {
    a || setTimeout(() => {
      $("button.toggle-fullscreen").click();
      setTimeout(() => {
        $(".save-or-cancel").append('<span style="color:#646464">ctrl+enter = submit | esc = exit</span>');
      }, 500);
    }, 500);
  }
  function ia(a, b, d) {
    function f(c) {
      let g = a.b.left;
      g.scrollTop >= g.scrollHeight - g.clientHeight - 1 && c.preventDefault();
    }
    let e = a.lookup("controller:application"),
      l = "dpg",
      h = document.createElement("style");
    document.head.appendChild(h);
    b.forEach(c => {
      c = c.topic_url.split("/").pop();
      h.sheet.insertRule(`html.dpg .category-page .topic-list-item.category-page[data-topic-id="${c}"] { display: none; }`);
    });
    d && (l += " dpg-hide-balloon-cat", d.forEach(c => {
      let g = c.slug;
      h.sheet.insertRule(`html.dpg.dpg-hide-balloon-cat .category-chooser .category-row[data-name="${c.name}"] { display: none; }`);
      (c = c.parentCategory) ? (c = c.slug, h.sheet.insertRule(`html.dpg body.category-${c}-${g} .category-navigation button#create-topic { opacity: 0.5; pointer-events: none; }`), h.sheet.insertRule(`html.dpg body.category-${c}-${g} .topic-list-bottom .footer-message { display: none; }`)) : (h.sheet.insertRule(`html.dpg body.category-${g} .category-navigation button#create-topic { opacity: 0.5; pointer-events: none; }`), h.sheet.insertRule(`html.dpg body.category-${g} .topic-list-bottom .footer-message { display: none; }`));
    }));
    e.siteSettings.discpage_hide_sugg_topics && (l += " dpg-disable-sugg");
    e.siteSettings.discpage_hide_tags && (l += " dpg-hide-tags");
    $("html").addClass(l);
    $("body").prepend('\n    <div id="dpg-ghost">\n      <div class="dpg-ghost-splitbar"></div>\n    </div>\n    <div id="dpg-container">\n      \x3c!-- <div id="dpg-ios-wrapper" tabindex="0"> --\x3e\n        <div id="dpg-left" tabindex="0">\n          \x3c!--\n          <div class="container">\n            <div class="loading-container visible ember-view">    \n              <div class="spinner "></div>\n            </div>      \n          </div>                \n          --\x3e\n        </div>\n        \x3c!-- </div> --\x3e\n      <div id="dpg-splitbar">\n        <div style="flex:1 0 0"></div>\n        <div id="dpg-splitbar-text">&gt;</div>\n        <div style="flex:1 0 0"></div>\n      </div>\n    </div>\n  ');
    a.b = new ha(e, b);
    a.b.left.addEventListener("wheel", c => {
      0 > c.deltaY ? 0 === a.b.left.scrollTop && c.preventDefault() : 0 < c.deltaY && f(c);
    }, {
      passive: !1
    });
    a.b.left.addEventListener("keydown", c => {
      c.shiftKey || c.altKey || c.ctrlKey || c.metaKey || ("ArrowUp" !== c.code && "PageUp" !== c.code || 0 !== a.b.left.scrollTop || c.preventDefault(), "ArrowDown" !== c.code && "PageDown" !== c.code || f(c));
    });
    let q = a.lookup("router:main");
    $("#dpg-splitbar").click(function () {
      let c = !a.b.j.get("showRight");
      q.transitionTo({
        queryParams: {
          showRight: c
        }
      });
    });
    a.b.left.addEventListener("click", c => {
      2 !== a.b.l && 3 !== a.b.l || c.shiftKey || c.ctrlKey || window.getSelection().toString() || c.target.closest(".lightbox-wrapper") || c.target.closest(".dpg-buttons") || q.transitionTo(`/t/${a.b.a}`);
    });
    document.addEventListener("click", c => {
      c.target.closest('[data-link-name="dpg-on-off"]') && $("html").toggleClass("dpg");
    });
    $(document).keydown(function (c) {
      65 === c.keyCode && c.altKey && ((c = _user.default.current()) && c.admin ? $("html").toggleClass("dpg") : x("Only admins can do that"));
    });
  }
  function ja(_ref11) {
    let {
      u: a,
      A: b,
      w: d,
      v: f,
      B: e
    } = _ref11;
    if (b.startsWith("topic.")) {
      let l = a.lookup("route:topic").modelFor("topic");
      if (!f.includes(l.get("category_id"))) {
        F(() => l.hasOwnProperty("tags"), 15, 200).then(() => {
          W({
            u: a,
            A: b,
            w: d,
            v: f,
            B: e
          });
        }, () => {
          U(a.b, 1);
        });
        return;
      }
    }
    W({
      u: a,
      A: b,
      w: d,
      v: f,
      B: e
    });
  }
  function W(_ref12) {
    let {
      u: a,
      A: b,
      w: d,
      v: f,
      B: e
    } = _ref12;
    let l = $("html");
    l.removeClass("dpg-page dpg-tag dpg-topic dpg-comment dpg-discuss");
    l.removeAttr("data-dpg-page-id");
    if (b.startsWith("topic.")) {
      let h = a.lookup("route:topic").currentModel;
      if (f.includes(h.get("category_id"))) {
        l.addClass("dpg-page");
        l.attr("data-dpg-page-id", h.get("id"));
        return;
      }
      let q,
        c = (h.get("tags") || []).find(g => {
          q = J(g);
          return !!q;
        });
      if (c) {
        let {
          a: g,
          s: k
        } = q;
        b = a.b.j.get("showRight") ? 3 : 2;
        S(a.b, {
          a: g,
          f: k
        });
        l.addClass("dpg-topic dpg-discuss");
        l.attr("data-dpg-page-id", g);
        d || X().then(() => {
          $("#dpg-back").length || $("#topic-title .title-wrapper").append(`
        <div id="dpg-back">
          <a href="/tag/${c}">
            &#8630; Back to topic list
          </a>
        </div>
      `);
        });
        U(a.b, b);
        return;
      }
    }
    if ("tag.show" === b && (b = a.lookup("route:tag.show").currentModel, b = J(b.tag.id))) {
      l.addClass("dpg-tag dpg-discuss");
      l.attr("data-dpg-page-id", b.a);
      d || (S(a.b, {
        a: b.a,
        f: b.s
      }), e && (1 === e.length ? a.currentTriggerCatId = e[0].id : ka(`/t/${b.a}.json`).then(h => {
        let q = h.category_id,
          c = a.lookup("controller:application").site.categories.find(g => g.id === q).parent_category_id;
        h = c && e.find(g => g.parent_category_id === c || g.id === c) || e[0];
        a.currentTriggerCatId = h.id;
      })), X().then(() => {
        {
          let h = $("footer.topic-list-bottom");
          $("table.topic-list").length ? h.html("") : h.html('\n      <div style="margin-left:12px">\n        <p><i>No topic yet</i></p>\n      </div>\n    ');
        }
      }));
      d = a.b.j.get("showRight") ? 3 : 2;
      U(a.b, d);
      return;
    }
    U(a.b, 1);
  }
  let X = () => new Promise(a => {
      (0, _runloop.schedule)("afterRender", null, () => a(void 0));
    }),
    ka = a => new Promise((b, d) => {
      $.get(a, f => b(f)).fail(() => d(`get "${a}" failed`));
    }),
    Y = () => new Promise(a => {
      (0, _runloop.schedule)("afterRender", null, () => a(void 0));
    });
  function Z(a, b) {
    z(`Invalid Discourse setting "${a.replace(/_/g, " ")}": ${b}`);
  }
  function init(a) {
    var b = a.lookup("site-settings:main");
    let d = _user.default.current(),
      f = d && d.admin;
    if (b.discpage_enabled && (!b.login_required || d)) if (b.tagging_enabled) {
      if ("header dropdown" != b.navigation_menu) Z("navigation_menu", 'this must be set to "Header Dropdown"');else if (b.discpage_page_categories) {
        var e = b.discpage_page_categories.split("|").map(k => parseInt(k)),
          l = a.lookup("controller:application"),
          h = !1,
          q = e.reduce((k, r) => {
            const u = l.site.categories.find(w => w.id === r);
            u ? k.push(u) : f && (Z("discpage_page_categories", `category "${r}" not found. Please reset this setting and add your category(ies) again`), h = !0);
            return k;
          }, []);
        if (!h) {
          b = l.siteSettings.discpage_balloon_category;
          h = !1;
          var c = b && b.split("|").reduce((k, r) => {
            const u = parseInt(r);
            (r = l.site.categories.find(w => w.id === u)) ? k.push(r) : f && (Z("discpage_balloon_category", `category "${u}" not found. Please reset this setting and add your category(ies) again`), h = !0);
            return k;
          }, []);
          if (!h) {
            Y().then(() => {
              ia(a, q, c);
            });
            a.lookup("controller:application").reopen({
              queryParams: {
                showRight: "r"
              },
              showRight: !0
            });
            var g = "";
            (0, _pluginApi.withPluginApi)("0.8.36", k => {
              k.modifyClass("component:discourse-topic", {
                pluginId: "discPage",
                shouldShowTopicInHeader() {
                  return !1;
                }
              });
              f && k.addCommunitySectionLink({
                name: "dpg-on-off",
                title: "DiscPage On/Off",
                text: "DiscPage On/Off",
                icon: "power-off"
              });
              k.modifyClass("model:composer", {
                open(r) {
                  r && "createTopic" === r.action && r.topicTags && J(r.topicTags) && (r.categoryId = a.currentTriggerCatId);
                  return this._super(...arguments);
                }
              });
              k.decorateWidget("header:before", r => {
                Y().then(() => {
                  a.b.G = r;
                  a.b.G.widget.model = {
                    can_edit: !1
                  };
                });
              });
              k.decorateWidget("post:after", r => {
                let u = r.attrs;
                if (u.firstPost) {
                  var w = a.lookup("controller:topic").get("model.category_id");
                  q.find(y => y.id == w) && Y().then(() => {
                    S(a.b, {
                      a: u.topicId.toString(),
                      i: u.id,
                      h: u.version,
                      c: u.cooked,
                      title: $(".fancy-title").text().trim()
                    });
                    U(a.b, 0);
                  });
                }
              });
              k.onAppEvent("page:changed", _ref13 => {
                let {
                  ["currentRouteName"]: r,
                  ["url"]: u
                } = _ref13;
                if (u !== g) {
                  var w = u.split("?")[0] === g.split("?")[0];
                  g = u;
                  ja({
                    u: a,
                    A: r,
                    w,
                    v: e,
                    B: c
                  });
                }
              });
            });
            _topicNavigation.default.reopen({
              _performCheckSize() {
                this._super();
                1005 >= $("html.dpg.dpg-topic #main-outlet").width() && this.info.setProperties({
                  renderTimeline: !1
                });
              },
              didInsertElement() {
                this._super(...arguments);
                this.observer = new MutationObserver(k => {
                  k.forEach(r => {
                    "class" === r.attributeName && r.target.classList.contains("dpg-topic") && this._performCheckSize();
                  });
                });
                this.observer.observe(document.documentElement, {
                  attributes: !0
                });
              },
              willDestroyElement() {
                this.observer.disconnect();
                this._super(...arguments);
              }
            });
            _application.default.reopen({
              S: _ember.default.observer("topicTrackingState.messageCount", function () {})
            });
          }
        }
      } else Z("discpage_page_categories", "missing setting");
    } else Z("tagging_enabled", "this must be set to true");
  }
});