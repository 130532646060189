define("discourse/plugins/discpage/discourse/initializers/discpage", ["exports", "discourse/plugins/discpage/discourse/lib/lib"], function (_exports, _lib) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //------------------------------------------------------------------------------
  //import discourseComputed from "discourse-common/utils/decorators";
  //import Topic from 'discourse/models/topic'
  //------------------------------------------------------------------------------
  var _default = _exports.default = {
    name: 'discpage',
    initialize(container, app) {
      /*
      DOESN'T WORK
      // Disable going to last read position in topics. See:
      // https://github.com/discourse/discourse/blob/main/app/assets/javascripts/discourse/app/models/topic.js#L258
      Topic.reopen({
        @discourseComputed("last_read_post_number", "highest_post_number", "url")
        lastUnreadUrl(lastReadPostNumber, highestPostNumber) {
          console.log('diuygfeziufgzeuifzegiu');
          const res = this._super(...arguments);
          return this.urlForPostNumber(1);
        }
      })    
      */

      (0, _lib.init)(container, app);
    }
  };
});